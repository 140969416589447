import React, { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap';

import "./Home.scss"

const Home = () => {
  useEffect(() => {
    const stepWords = document.querySelectorAll('.step .word');
    const truthWords = document.querySelectorAll('.truth .word');
    const studioWords = document.querySelectorAll('.studio');

    const tlStep = gsap.timeline();
    const tlTruth = gsap.timeline();
    const tlStudio = gsap.timeline();

    gsap.utils.toArray(stepWords).forEach((word, index) => {
      tlStep.to(word, {
        opacity: 1,
        ease: "power4.out",
        delay: index / 1000,
        duration: 0.22,
        stagger: 0.5
      });
    });

    tlTruth.to(truthWords, {
      opacity: 1,
      ease: "sine.in",
      delay: 1.5,
      duration: 0.7,
      stagger: 0.5,
    });

    gsap.utils.toArray(studioWords).forEach((word, index) => {
      tlStudio.to(studioWords, {
        opacity: 1,
        ease: "power4.out",
        delay: 3,
        duration: 0.22,
        stagger: 0.5
      });
    });
  }, []);

  return (
    <div className="home">
      <div className='landing'>
        <div className="step">
          <span className="word">we're</span><span className="word">a</span><span className="word">creative</span><span className="word">technology</span><span className="word">studio</span>
        </div>
        <div className="truth">
          <span className="word">and we kinda just wanna build stuff that doesn't exist</span>
        </div>
      </div>
      <div className='services'>
        <ul>
          <li className="studio">Creative Technology (AR/VR, AI, hardware)</li>
          <li className="studio">Full Stack Web Apps</li>
          <li className="studio">Mobile Apps</li>
          <li className="studio">Immersive Web</li>
          <li className="studio">Prototypes</li>
        </ul>
      </div>
    </div>
  );
}



export default Home;