import React from 'react'
import { Widget, PopupButton } from '@typeform/embed-react'

import "./Apply.scss"

const Apply = () => {
    return (
        <div className='apply'>
            <Widget
                size={60}
                id="ecozUB9S"
                className="contact-form" />
        </div>
    )
}

export default Apply
