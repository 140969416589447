import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/Header/Header.jsx";
import Footer from "./components/Footer/Footer.jsx";

import Home from "./pages/Home/Home.jsx";

import "./App.css";
import Apply from "./pages/Apply/Apply.jsx";

const App = () => {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route index path="/apply" element={<Apply />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default App;
