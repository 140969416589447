import React from 'react';
import { Link } from 'react-router-dom';
import logo2 from "../../assets/images/logo2.png"

import './Header.scss';

import igLogo from "../../assets/images/ig-logo.png"

const Header = () => {
  return (
    <div className="header">
      <div className='header-left'>
        <Link to={"/"}>
          <img src={logo2} alt="step" width={75} />
        </Link>
      </div>
      <div className='header-center'>
      </div>
      <div className="header-right">
        <Link to={"/apply"}>1. Apply</Link>
        <a style={{ marginTop: '2px' }} href="https://instagram.com/step.studios" target='_blank'><img src={igLogo} width={24} alt='instagram-logo' /></a>
      </div>
    </div>
  );
}

export default Header;