import React, { useEffect, useState } from 'react';

import './Footer.scss';

const Footer = () => {
  const [mousePos, setMousePos] = useState({});


  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener(
        'mousemove',
        handleMouseMove
      );
    };
  }, []);

  return (
    <div className="footer">
      <div className="footer-center">
        <p><a href='mailto:hello@stepstudios.xyz'>hello@stepstudios.xyz</a></p>
      </div>
      <div className='footer-right'>
        (x: {mousePos.x}, y: {mousePos.y})
      </div>
    </div>
  );
}

export default Footer;